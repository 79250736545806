.caller-overview-style {
    border: 1px solid #D6D8D9;
    box-sizing: border-box;
    border-radius: 8px;
    margin-top: 16px;
    margin-bottom: 8px;
  }
  
  .caller-overview {
    margin: 5px 16px;
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
  }

  .detail-subSection {
    border-bottom: 1px solid #D6D8D9;
    display: flex;
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
  }

  .detail-subSection {
    display: flex;
    flex-wrap: wrap;
  }
  
  .detail-subSection .input-label-container {
    flex-basis: 100%;
    max-width: calc(100% - 116px);
  }

  .multipleOptions {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0px;
    margin: 10px 16px;
    border-bottom: 1px solid #D6D8D9;
    justify-content: space-between;
  }

  .multipleOptionsItem {
    flex: 1 0 30%
  }